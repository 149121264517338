import carData from '@/data/default_car_data.json';
import suvData from '@/data/default_suv_data.json';
import truckData from '@/data/default_truck_data.json';
import CarPlaceholder from '@/images/suggested/CarPlaceholder.webp';
import SUVPlaceholder from '@/public/custom-cover/sc-01-ps-gr-1to-m.webp';
import TruckPlaceholder from '@/public/custom-cover/tc-01-ps-gr-1to-m.webp';
import defaultCarPremiumPlus from '@/images/default-product-images/01-bkrd-str-m.jpg';
import defaultSUVPremiumPlus from '@/public/pms/suv-01-bkgr-str-m.webp';
import defaultTruckPremiumPlus from '@/public/pms/tc-01-bkgr-str-m.webp';
import defaultTruckPremium from '@/public/pms/tc-01-ps-gr-1to-m.webp';

export const modelStrings: Record<string, string> = {
  'car-covers': 'Car Covers',
  'suv-covers': 'SUV Covers',
  'van-covers': 'Van Covers',
  'truck-covers': 'Truck Covers',
  'austin-healey': 'Austin-Healey',
  'rolls-royce': 'Rolls-Royce',
  bmw: 'BMW',
  db11: 'DB11',
  db7: 'DB7',
  db9: 'DB9',
  dbs: 'DBS',
  nsx: 'NSX',
  'continental-gt': 'Continental GT',
  'continental-gtc': 'Continental GTC',
  'continental-flying-spur': 'Continental Flying Spur',
  'continental-supersports': 'Continental Supersports',
  'turbo-r': 'Turbo R',
  '2002ti': '2002Ti',
  '2002tii': '2002Tii',
  '3000me': '3000ME',
  '1-series': '1-Series',
  '2-series': '2-Series',
  '3-series': '3-Series',
  '4-series': '4-Series',
  '5-series': '5-Series',
  '6-series': '6-Series',
  '7-series': '7-Series',
  '7-series-lwb': '7-Series LWB',
  '8-series': '8-Series',
  kia: 'Kia',
  'ct4-v': 'CT4-V',
  'ct4-v-blackwing': 'CT4-V Blackwing',
  'ct5-v-blackwing': 'CT5-V Blackwing',
  'ct5-v': 'CT5-V',
  'integra-type-r': 'Integra Type-R',
  mito: 'MiTo',
  'a3-e-tron': 'A3 e-tron',
  'e-tron-gt': 'e-tron GT',
  'rs-e-tron-gt': 'RS e-tron GT',
  'e-tron': 'E-Tron',
  '100-6': '100-6',
  'r-type': 'R-Type',
  'new-six-(e3)': 'New Six (E3)',
  'new-six-(e9)': 'New Six (E9)',
  i3: 'i3',
  i4: 'i4',
  i8: 'i8',
  '1600-2': '1600-2',
  '2.8l-3.3li': '2.8L-3.3Li',
  'new-class': 'New Class',
  'sv-1': 'SV-1',
  'gran-sport-(gs)': 'Gran Sport (GS)',
  'coupe-deville': 'Coupe DeVille',
  'sedan-deville': 'Sedan DeVille',
  'xts-l': 'XTS-L',
  'hardtop-sedan-2-door': 'Hardtop Sedan 2-Door',
  'ami-6': 'AMI-6',
  sp250: 'SP250',
  db18: 'DB18',
  delorean: 'DeLorean',
  'dmc-12': 'DMC-12',
  desoto: 'DeSoto',
  detomaso: 'DeTomaso',
  'st.-regis': 'St. Regis',
  '456m': '456M',
  '512bb': '512BB',
  '512bbi': '512BBi',
  '575m-maranello': '575M Maranello',
  'x-1-9': 'x 1-9',
  '2-door-coupe': '2-Door Coupe',
  '2-door-sedan': '2-Door Sedan',
  '4-door-sedan': '4-Door Sedan',
  'c-max': 'C-Max',
  'capri-mk-i': 'Capri Mk I',
  'capri-mk-ii': 'Capri Mk II',
  'mustang-shelby-gt350': 'Mustang Shelby GT350',
  'mustang-shelby-gt350r': 'Mustang Shelby GT350R',
  'mustang-shelby-gt500': 'Mustang Shelby GT500',
  'mustang-shelby-gt500kr': 'Mustang Shelby GT500KR',
  geo: 'Geo',
  'civic-del-sol': 'Civic Del Sol',
  'cr-z': 'CR-Z',
  xg300: 'XG300',
  xg350: 'XG350',
  q70l: 'Q70L',
  iso: 'Iso',
  'f-type': 'F-Type',
  's-type': 'S-Type',
  'x-type': 'X-Type',
  'xj-series-x300': 'XJ-Series X300',
  'xk-series-xk120': 'XK-Series XK120',
  'xk-series-xk140': 'XK-Series XK140',
  'xk-series-xk150': 'XK-Series XK150',
  xj12: 'XJ12',
  xj40: 'XJ40',
  xj8l: 'XJ8L',
  'jensen-healey': 'Jensen-Healey',
  'mark-viii': 'Mark VIII',
  mc20: 'MC20',
  granturismo: 'GranTurismo',
  'miata-mx-5': 'Miata MX-5',
  'mx-3': 'MX-3',
  'mx-6': 'MX-6',
  'rx-2': 'RX-2',
  'rx-7': 'RX-7',
  'rx-8': 'RX-8',
  mazdaspeed3: 'MazdaSpeed3',
  mazdaspeed6: 'MazdaSpeed6',
  '180-series': '180-Series',
  '190d': '190D',
  '190db': '190Db',
  '190dc': '190Dc',
  '190e': '190E',
  '200ce': '200CE',
  '200d': '200D',
  '200e': '200E',
  '200t': '200T',
  '200td': '200TD',
  '200te': '200TE',
  '220a': '220A',
  '220d': '220D',
  '220s': '220S',
  '220sb': '220Sb',
  '220se': '220SE',
  '230c': '230C',
  '230ce': '230CE',
  '230e': '230E',
  '230t': '230T',
  '230te': '230TE',
  '240d': '240D',
  '240td': '240TD',
  '250c': '250C',
  '250ce': '250CE',
  '250d': '250D',
  '250s': '250S',
  //NEED TO FINISH MERCEDES....
  '2-door': '2-Door',
  '4-door': '4-Door',
  xr4ti: 'XR4TI',
  '3000gt': '3000GT',
  'nash-hudson': 'Nash-Hudson',
  '240sx': '240SX',
  '240z': '240Z',
  '260z': '260Z',
  '280z': '280Z',
  '280zx': '280ZX',
  '300zx': '300zx',
  '350z': '350Z',
  '350z-nismo': '350Z Nismo',
  '370z': '370Z',
  '370z-nismo': '370Z Nismo',
  'gt-r': 'GT-R',
  'gt-r-nismo': 'GT-R Nismo',
  'skyline-gt-r-(r32)': 'Skyline GT-R (R32)',
  'skyline-gt-r-(r33)': 'Skyline GT-R (R33)',
  'skyline-gt-r-(r34)': 'Skyline GT-R (R34)',
  'f-85': 'F-85',
  lemans: 'LeMans',
  'trans-am-silver-anniversary': 'Trans Am Silver Anniversary',
  '356a': '356A',
  '356b': '356B',
  '356c': '356C',
  'corolla-im': 'Corolla iM',
  gr86: 'GR86',
  'prius-plug-in-hybrid': 'Prius Plug-In Hybrid',
  'yaris-ia': 'Yaris iA',
  'e-golf': 'E-Golf',
  eos: 'Eos',
  pv444: 'PV444',
  pv544: 'PV544',
  sky: 'Sky',
  ac: 'AC',
  'silverado-1500hd': 'Silverado 1500HD',
  'silverado-2500hd': 'Silverado 2500HD',
  'silverado-3500hd': 'Silverado 3500HD',
  'ram-1500': 'Ram 1500',
  'ram-2500': 'Ram 2500',
  'ram-3500': 'Ram 3500',
  'f-100': 'F-100',
  'f-150': 'F-150',
  'f-150-lightning': 'F-150 Lightning',
  'f-150-raptor': 'F-150 Raptor',
  'f-150-svt-raptor': 'F-150 SVT Raptor',
  'f-250': 'F-250',
  'f-250-super-duty': 'F-250 Super Duty',
  'f-350-super-duty': 'F-350 Super Duty',
  'f-450-super-duty': 'F-450 Super Duty',
  'sierra-1500hd': 'Sierra 1500HD',
  'sierra-2500hd': 'Sierra 2500HD',
  'sierra-3500hd': 'Sierra 3500HD',
  gwm: 'GWM',
  'cannon-l-4x4': 'Cannon L 4x4',
  'ute-cannon-x-4x4': 'Ute Cannon-X 4x4',
  'colorado-sportscat': 'Colorado SportsCat',
  'br-v': 'BR-V',
  'cr-v': 'CR-V',
  'hr-v': 'HR-V',
  'cr-v-hybrid': 'CR-V Hybrid',
  'e-pace': 'E-Pace',
  'f-pace': 'F-Pace',
  'i-pace': 'I-Pace',
  'mustang-mach-e': 'Mustang Mach-E',
  ecosport: 'EcoSport',
  'cx-3': 'CX-3',
  'cx-0': 'CX-0',
  'cx-5': 'CX-5',
  'cx-6': 'CX-6',
  'cx-7': 'CX-7',
  'cx-8': 'CX-8',
  'cx-9': 'CX-9',
  'mazda-2': 'MAZDA 2',
  'mx-30': 'MX-30',
  '500l': '500L',
  '500x': '500X',
  'rx-350l,-450hl': 'RX 350L, 450hL',
  'murano-crosscabriolet': 'Murano CrossCabriolet',
  'x-trail': 'X-Trail',
  'series-60': 'SERIES 60',
  '9-4x': '9-4X',
  '9-7x': '9-7X',
  vue: 'Vue',
  '4runner': '4Runner',
  'c-hr': 'C-HR',
  fj40: 'FJ40',
  rav4: 'RAV4',
  'rav4-hybrid': 'RAV4 Hybrid',
  'rav4-prime': 'RAV4 Prime',
  bz4x: 'bZ4X',
  'id.4': 'ID.4',
  't-cross': 'T-Cross',
  xc40: 'XC40',
  xc60: 'XC60',
  xc70: 'XC70',
  xc90: 'XC90',
  'xc40-recharge': 'XC40 Recharge',
  'xc60-recharge': 'XC60 Recharge',
  'xc90-recharge': 'XC90 Recharge',
  'travelall-1200b': 'Travelall 1200B',
  'cj-5': 'CJ-5',
  'cj-6': 'CJ-6',
  'cj-7': 'CJ-7',
  'f-134': 'F-134',
  'grand-cherokee-srt-8': 'Grand Cherokee SRT-8',
  'niva-pick-up': 'Niva Pick-Up',
  'mercedes-benz': 'Mercedes-Benz',
  'g550-4x4': 'G550 4x4',
  'gla-class': 'GLA-Class',
  'glb-class': 'GLB-Class',
  'glc-class': 'GLC-Class',
  'gle-class': 'GLE-Class',
  'glk-class': 'GLK-Class',
  'gl-class': 'GL-Class',
  'm-class-ml': 'M-Class ML',
  'r-class': 'R-Class',
  'eqb-class': 'EQB-Class',
  'eqe-class': 'EQE-Class',
  'eqs-class-suv': 'EQS-Class SUV',
  'glc-class-coupe': 'GLC-Class Coupe',
  'gle-class-coupe': 'GLE-Class Coupe',
  'q4-e-tron': 'Q4 e-tron',
  'q4-sportback-e-tron': 'Q4 Sportback e-tron',
  'q8-e-tron': 'Q8 e-tron',
  'q8-sportback-e-tron': 'Q8 Sportback e-tron',
  ex35: 'EX35',
  fx35: 'FX35',
  fx45: 'FX45',
  fx50: 'FX50',
  qx30: 'QX30',
  qx4: 'QX4',
  qx50: 'QX50',
  qx55: 'QX55',
  qx56: 'QX56',
  qx60: 'QX60',
  qx70: 'QX70',
  qx80: 'QX80',
  '427': '427',
} as const;

export const US_STATES = [
  { name: 'Alabama', abbreviation: 'AL' },
  // { name: 'Alaska', abbreviation: 'AK' },
  { name: 'Arizona', abbreviation: 'AZ' },
  { name: 'Arkansas', abbreviation: 'AR' },
  { name: 'California', abbreviation: 'CA' },
  { name: 'Colorado', abbreviation: 'CO' },
  { name: 'Connecticut', abbreviation: 'CT' },
  { name: 'Delaware', abbreviation: 'DE' },
  { name: 'Florida', abbreviation: 'FL' },
  { name: 'Georgia', abbreviation: 'GA' },
  // { name: 'Hawaii', abbreviation: 'HI' },
  { name: 'Idaho', abbreviation: 'ID' },
  { name: 'Illinois', abbreviation: 'IL' },
  { name: 'Indiana', abbreviation: 'IN' },
  { name: 'Iowa', abbreviation: 'IA' },
  { name: 'Kansas', abbreviation: 'KS' },
  { name: 'Kentucky', abbreviation: 'KY' },
  { name: 'Louisiana', abbreviation: 'LA' },
  { name: 'Maine', abbreviation: 'ME' },
  { name: 'Maryland', abbreviation: 'MD' },
  { name: 'Massachusetts', abbreviation: 'MA' },
  { name: 'Michigan', abbreviation: 'MI' },
  { name: 'Minnesota', abbreviation: 'MN' },
  { name: 'Mississippi', abbreviation: 'MS' },
  { name: 'Missouri', abbreviation: 'MO' },
  { name: 'Montana', abbreviation: 'MT' },
  { name: 'Nebraska', abbreviation: 'NE' },
  { name: 'Nevada', abbreviation: 'NV' },
  { name: 'New Hampshire', abbreviation: 'NH' },
  { name: 'New Jersey', abbreviation: 'NJ' },
  { name: 'New Mexico', abbreviation: 'NM' },
  { name: 'New York', abbreviation: 'NY' },
  { name: 'North Carolina', abbreviation: 'NC' },
  { name: 'North Dakota', abbreviation: 'ND' },
  { name: 'Ohio', abbreviation: 'OH' },
  { name: 'Oklahoma', abbreviation: 'OK' },
  { name: 'Oregon', abbreviation: 'OR' },
  { name: 'Pennsylvania', abbreviation: 'PA' },
  { name: 'Rhode Island', abbreviation: 'RI' },
  { name: 'South Carolina', abbreviation: 'SC' },
  { name: 'South Dakota', abbreviation: 'SD' },
  { name: 'Tennessee', abbreviation: 'TN' },
  { name: 'Texas', abbreviation: 'TX' },
  { name: 'Utah', abbreviation: 'UT' },
  { name: 'Vermont', abbreviation: 'VT' },
  { name: 'Virginia', abbreviation: 'VA' },
  { name: 'Washington', abbreviation: 'WA' },
  { name: 'West Virginia', abbreviation: 'WV' },
  { name: 'Wisconsin', abbreviation: 'WI' },
  { name: 'Wyoming', abbreviation: 'WY' },
  // { name: 'Guam', abbreviation: 'GU' },
  // { name: 'US Virgin Islands', abbreviation: 'VI' },
  // { name: 'Puerto Rico', abbreviation: 'PR' },
] as const;

export const US_STATES_ABBRV_MAP: Record<string, string> = {
  AL: 'Alabama',
  // AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  FL: 'Florida',
  GA: 'Georgia',
  // HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
  // DC: 'District of Columbia',
  // PR: 'Puerto Rico',
  // GU: 'Guam',
  // VI: 'Virgin Islands',
  // MP: 'Northern Mariana Islands',
  // AS: 'American Samoa',
  // AE: 'Armed Forces Europe',
  // AP: 'Armed Forces Pacific',
  // AA: 'Armed Forces Americas'
};

export const SHIPPING_RATES = [
  {
    shipping_type: 'Free Shipping',
    shipping_cost: 0,
    shipping_method: 'FedEx Ground',
    product_types: ['Car Covers', 'SUV Covers', 'Truck Covers'],
    states_excluded: ['AK', 'HI', 'PR', 'VI', 'GU'],
  },
  {
    shipping_type: 'Paid Shipping',
    shipping_cost: 25,
    shipping_method: 'FedEx 2-Day Guaranteed Delivery',
    product_types: ['Car Covers', 'SUV Covers'],
    states_excluded: ['GU', 'VI', 'PR'],
  },
  {
    shipping_type: 'Paid Shipping',
    shipping_cost: 50,
    shipping_method: 'FedEx 2-Day Guaranteed Delivery',
    product_types: ['Car Covers', 'SUV Covers'],
    states_excluded: ['AK', 'HI'],
  },
  {
    shipping_type: 'Paid Shipping',
    shipping_cost: 65,
    shipping_method: 'FedEx 2-Day Guaranteed Delivery',
    product_types: ['Truck Cover'],
    states_excluded: [],
  },
] as const;

export const slugToProductType = {
  'car-covers': 'Car Covers',
  'suv-covers': 'SUV Covers',
  'truck-covers': 'Truck Covers',
};

export const slugToCoverType = {
  'premium-plus': 'Premium Plus',
  premium: 'Premium',
  'standard-pro': 'Standard Pro',
  standard: 'Standard',
  leatherette: 'Leatherette',
  leather: 'Leather',
  Leather: 'Leather',
  textured: 'Textured',
  Textured: 'Textured',
};

export const PRODUCT_PRICING_DATA = {
  'car-covers': [
    {
      msrp: '159.95',
      price: '320.00',
      description: 'Fully Custom Car Cover',
      image: defaultCarPremiumPlus,
      type: {
        slug: 'premium-plus',
        display: 'Premium Plus',
      },
    },
    {
      msrp: '119.95',
      price: '240.00',
      description: 'Semi-Custom Car Cover',
      image: CarPlaceholder,
      type: {
        slug: 'premium',
        display: 'Premium',
      },
    },

    {
      msrp: '100.00',
      price: '100.00',
      description: 'Semi-Custom Car Cover',
      image: CarPlaceholder,
      type: {
        slug: 'standard-pro',
        display: 'Standard Pro',
      },
    },
    {
      msrp: '80.00',
      price: '80.00',
      description: 'Semi-Custom Car Cover',
      image: CarPlaceholder,
      type: {
        slug: 'standard',
        display: 'Standard',
      },
    },
  ],
  'suv-covers': [
    {
      msrp: '179.95',
      price: '360.00',
      description: 'Fully Custom SUV Cover',
      image: defaultSUVPremiumPlus,
      type: {
        slug: 'premium-plus',
        display: 'Premium Plus',
      },
    },
    {
      msrp: '139.95',
      price: '280.00',
      description: 'Semi-Custom SUV Cover',
      image: SUVPlaceholder,
      type: {
        slug: 'premium',
        display: 'Premium',
      },
    },

    {
      msrp: '120.00',
      price: '120.00',
      description: 'Semi-Custom SUV Cover',
      image: SUVPlaceholder,
      type: {
        slug: 'standard-pro',
        display: 'Standard Pro',
      },
    },

    {
      msrp: '100.00',
      price: '100.00',
      description: 'Semi-Custom SUV Cover',
      image: SUVPlaceholder,
      type: {
        slug: 'standard',
        display: 'Standard',
      },
    },
  ],
  'truck-covers': [
    {
      msrp: '199.95',
      price: '400.00',
      description: 'Fully Custom Truck Cover',
      image: defaultTruckPremiumPlus,
      type: {
        slug: 'premium-plus',
        display: 'Premium Plus',
      },
    },
    {
      msrp: '159.95',
      price: '320.00',
      description: 'Semi-Custom Truck Cover',
      image: defaultTruckPremium,
      type: {
        slug: 'premium',
        display: 'Premium',
      },
    },

    {
      msrp: '140.00',
      price: '140.00',
      description: 'Semi-Custom Truck Cover',
      image: TruckPlaceholder,
      type: {
        slug: 'standard-pro',
        display: 'Standard Pro',
      },
    },
    {
      msrp: '120.00',
      price: '120.00',
      description: 'Semi-Custom Truck Cover',
      image: TruckPlaceholder,
      type: {
        slug: 'standard',
        display: 'Standard',
      },
    },
  ],
};

export type TProductPricingConstant = typeof PRODUCT_PRICING_DATA;

export const colorOrder = [
  'Black Red Stripe',
  'Black Gray Stripe',
  'Gray Black Stripe',
  'Black Gray 2-Tone',
  'Black Red 2-Tone',
  'Gray Black Tribe',
  'Solid Gray',
] as const;

export const typeOrder = [
  'Premium Plus',
  'Premium',
  'Standard Pro',
  'Standard',
];

export const skuTypes = [
  'SKU_pe_bkgr_str',
  'SKU_pe_bkrd_str',
  'SKU_pp_bkgr_str',
  'SKU_pp_bkrd_str',
  'SKU_pp_grbk_str',
  'SKU_pp_bkgr_2to',
  'SKU_pp_bkrd_2to',
  'SKU_pp_grbk_tri',
  'SKU_ps_gr_1to',
  'SKU_sp_gr_1to',
  'SKU_ss_gr_1to',
];

export const VIMEO_VIDEO_LINK = 'https://vimeo.com/904161479';

const suvImages: Partial<Record<(typeof colorOrder)[number], string[]>> = {
  'Black Gray Stripe': [
    'http://www.coverland.com/category-images-new/suv-premium-plus-bkgr-str.webp',
    'http://www.coverland.com/category-images-new/02-SC-ft-ps-bkgr-str.webp',
    'http://www.coverland.com/category-images-new/04-oxford-outer-black.webp',
    'http://www.coverland.com/category-images-new/05-oxford-inner.webp',
    'http://www.coverland.com/category-images-new/09-value.webp',
    'https://www.coverland.com/images/default-product-images/15.jpg',
  ],
  'Solid Gray': [
    'http://www.coverland.com/category-images-new/suv-standard-pro-gr-1to.webp',
    'http://www.coverland.com/category-images-new/02-SC-ft-sp-gr-1to.webp',
    'http://www.coverland.com/category-images-new/05-SC-ps-gr-1to.webp',
    'http://www.coverland.com/category-images-new/06-sp-gr-1to.webp',
    'http://www.coverland.com/category-images-new/07-sp-gr-1to.webp',
    'http://www.coverland.com/category-images-new/12.webp',
    'https://www.coverland.com/images/default-product-images/15.jpg',
  ],
};

const carImages: Partial<Record<(typeof colorOrder)[number], string[]>> = {
  'Black Red Stripe': [
    'https://coverland.sfo3.cdn.digitaloceanspaces.com/pms/01-bkrd-str-m.webp',
    'https://coverland.sfo3.cdn.digitaloceanspaces.com/pms/02-bkrd-str-m.webp',
    'https://coverland.sfo3.cdn.digitaloceanspaces.com/pms/03-bkrd-str-m.webp',
    'https://coverland.sfo3.cdn.digitaloceanspaces.com/pms/04-bkrd-str-m.webp',
    'https://coverland.sfo3.cdn.digitaloceanspaces.com/pms/05-bkrd-str-m.webp',
    'https://coverland.sfo3.cdn.digitaloceanspaces.com/pms/06-bkrd-str-m.webp',
    'https://coverland.sfo3.cdn.digitaloceanspaces.com/pms/07-bkrd-str-m.webp',
    'https://coverland.sfo3.cdn.digitaloceanspaces.com/pms/08-bkrd-str-m.webp',
    'https://coverland.sfo3.cdn.digitaloceanspaces.com/pms/09-bkrd-str-m.webp',
    'https://coverland.sfo3.cdn.digitaloceanspaces.com/pms/10-bkrd-str-m.webp',
    'https://coverland.sfo3.cdn.digitaloceanspaces.com/pms/11-bkrd-str-m.webp',
    'https://coverland.sfo3.cdn.digitaloceanspaces.com/pms/12-bkrd-str-m.webp',
  ],

  'Black Gray Stripe': [
    'https://coverland.sfo3.cdn.digitaloceanspaces.com/pms/01-bkgr-str-m.webp',
    'https://coverland.sfo3.cdn.digitaloceanspaces.com/pms/02-bkgr-str-m.webp',
    'https://coverland.sfo3.cdn.digitaloceanspaces.com/pms/03-bkgr-str-m.webp',
    'https://coverland.sfo3.cdn.digitaloceanspaces.com/pms/04-bkgr-str-m.webp',
    'https://coverland.sfo3.cdn.digitaloceanspaces.com/pms/05-bkgr-str-m.webp',
    'https://coverland.sfo3.cdn.digitaloceanspaces.com/pms/06-bkgr-str-m.webp',
    'https://coverland.sfo3.cdn.digitaloceanspaces.com/pms/07-bkgr-str-m.webp',
    'https://coverland.sfo3.cdn.digitaloceanspaces.com/pms/08-bkgr-str-m.webp',
    'https://coverland.sfo3.cdn.digitaloceanspaces.com/pms/09-bkgr-str-m.webp',
    'https://coverland.sfo3.cdn.digitaloceanspaces.com/pms/10-bkgr-str-m.webp',
    'https://coverland.sfo3.cdn.digitaloceanspaces.com/pms/11-bkgr-str-m.webp',
    'https://coverland.sfo3.cdn.digitaloceanspaces.com/pms/12-bkgr-str-m.webp',
  ],
  'Gray Black Stripe': [
    'https://coverland.sfo3.cdn.digitaloceanspaces.com/pms/01-grbk-str-m.webp',
    'https://coverland.sfo3.cdn.digitaloceanspaces.com/pms/02-grbk-str-m.webp',
    'https://coverland.sfo3.cdn.digitaloceanspaces.com/pms/03-grbk-str-m.webp',
    'https://coverland.sfo3.cdn.digitaloceanspaces.com/pms/04-grbk-str-m.webp',
    'https://coverland.sfo3.cdn.digitaloceanspaces.com/pms/05-grbk-str-m.webp',
    'https://coverland.sfo3.cdn.digitaloceanspaces.com/pms/06-grbk-str-m.webp',
    'https://coverland.sfo3.cdn.digitaloceanspaces.com/pms/07-grbk-str-m.webp',
    'https://coverland.sfo3.cdn.digitaloceanspaces.com/pms/08-grbk-str-m.webp',
    'https://coverland.sfo3.cdn.digitaloceanspaces.com/pms/09-grbk-str-m.webp',
    'https://coverland.sfo3.cdn.digitaloceanspaces.com/pms/10-grbk-str-m.webp',
    'https://coverland.sfo3.cdn.digitaloceanspaces.com/pms/11-grbk-str-m.webp',
    'https://coverland.sfo3.cdn.digitaloceanspaces.com/pms/12-grbk-str-m.webp',
  ],
  'Black Gray 2-Tone': [
    'http://www.coverland.com/pms/01-2to-bkgr.webp',
    'http://www.coverland.com/pms/02-bkgr-2to-mr.webp',
    'http://www.coverland.com/pms/03-2to-bkgr.webp',
    'http://www.coverland.com/pms/04-oxford-outer-black.webp',
    'http://www.coverland.com/pms/05-oxford-inner.webp',
    'http://www.coverland.com/pms/06-bkgr-2to.webp',
    'http://www.coverland.com/pms/07-uv-protection.webp',
    'http://www.coverland.com/pms/08-simulation.webp',
    'http://www.coverland.com/pms/09-value.webp',
    'http://www.coverland.com/pms/10-2to-bkgr.webp',
    'https://www.coverland.com/images/default-product-images/15.jpg',
  ],
  'Black Red 2-Tone': [
    'http://www.coverland.com/pms/01-2to-bkrd.webp',
    'http://www.coverland.com/pms/02-bkrd-2to-mr.webp',
    'http://www.coverland.com/pms/03-2to-bkrd.webp',
    'http://www.coverland.com/pms/04-oxford-outer-black.webp',
    'http://www.coverland.com/pms/05-oxford-inner.webp',
    'http://www.coverland.com/pms/06-bkrd-2to.webp',
    'http://www.coverland.com/pms/07-uv-protection.webp',
    'http://www.coverland.com/pms/08-simulation.webp',
    'http://www.coverland.com/pms/09-value.webp',
    'http://www.coverland.com/pms/10-2to-bkrd.webp',
    'https://www.coverland.com/images/default-product-images/15.jpg',
  ],
  'Gray Black Tribe': [
    'http://www.coverland.com/pms/01-tri-grbk.webp',
    'http://www.coverland.com/pms/02-grbk-tri-mr.webp',
    'http://www.coverland.com/pms/03-tri-grbk.webp',
    'http://www.coverland.com/pms/04-oxford-outer-black.webp',
    'http://www.coverland.com/pms/05-oxford-inner.webp',
    'http://www.coverland.com/pms/06-grbk-tri.webp',
    'http://www.coverland.com/pms/07-uv-protection.webp',
    'http://www.coverland.com/pms/08-simulation.webp',
    'http://www.coverland.com/pms/09-value.webp',
    'http://www.coverland.com/pms/10-tri-grbk.webp',
    'https://www.coverland.com/images/default-product-images/15.jpg',
  ],

  'Solid Gray': [
    'http://www.coverland.com/category-images-new/01-main-ps-gr-1to-nm.webp',
    'http://www.coverland.com/pms/02-gr-1to-nm.webp',
    'http://www.coverland.com/pms/03-gr-1to-nm.webp',
    'http://www.coverland.com/pms/04-ps-gr-1to-nm.webp',
    'http://www.coverland.com/pms/05-gr-1to-nm.webp',
    'http://www.coverland.com/pms/06-warranty-5.webp',
    'http://www.coverland.com/pms/07-gr-1to-nm.webp',
    'http://www.coverland.com/pms/08-gr-1to-nm.webp',
    'http://www.coverland.com/pms/09-gr-1to-nm.webp',
    'http://www.coverland.com/pms/10-gr-1to.webp',
    'http://www.coverland.com/pms/11-ww.webp',
    'http://www.coverland.com/pms/12-gr-1to-nm.webp',
  ],
};

const truckImages: Partial<Record<(typeof colorOrder)[number], string[]>> = {
  'Black Gray Stripe': [
    'http://www.coverland.com/category-images-new/01-str-bkgr-truck.webp',
    'http://www.coverland.com/category-images-new/02-str-bkgr-truck.webp',
    'http://www.coverland.com/category-images-new/04-oxford-outer-black.webp',
    'http://www.coverland.com/category-images-new/05-oxford-inner.webp',
    'http://www.coverland.com/category-images-new/09-value.webp',
    'https://www.coverland.com/images/default-product-images/15.jpg',
  ],
  'Solid Gray': [
    'http://www.coverland.com/category-images-new/truck-premium-gr-1to.webp',
    'http://www.coverland.com/category-images-new/02-tc-ft-sp-gr-1to.webp',
    'http://www.coverland.com/category-images-new/06-ps-gr-1to.webp',
    'http://www.coverland.com/category-images-new/07-ps-gr-1to.webp',
    'http://www.coverland.com/category-images-new/12.webp',
    'https://www.coverland.com/images/default-product-images/15.jpg',
  ],
};

export const DEFAULT_PRODUCT_IMAGES = { suvImages, carImages, truckImages };

export const BASE_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://www.coverland.com'
    : process.env.NODE_ENV === 'test'
      ? 'https://coverland-2-0.vercel.app'
      : 'http://localhost:3000';

export const CAR_COVERS = 'Car Covers';
export const SUV_COVERS = 'SUV Covers';
export const TRUCK_COVERS = 'Truck Covers';
export const SEAT_COVERS = 'Seat Covers';
export const FLOOR_MATS = 'Floor Mats';
export const CAR_COVERS_URL_PARAM = 'car-covers';
export const SUV_COVERS_URL_PARAM = 'suv-covers';
export const TRUCK_COVERS_URL_PARAM = 'truck-covers';
export const SEAT_COVERS_URL_PARAM = 'seat-covers';
export const FLOOR_MATS_URL_PARAM = 'floor-mats';
export const SEAT_COVERS_LEATHER_URL_PARAM = 'seat-covers/leather';
export const SEAT_COVERS_URL_PARAM_WITH_SLASH = '/seat-covers';
export const FLOOR_MATS_URL_PARAM_WITH_SLASH = '/floor-mats';

export const PREMIUM_PLUS = 'Premium Plus';
export const PREMIUM = 'Premium';
export const STANDARD_PRO = 'Standard Pro';
export const STANDARD = 'Standard';
export const PREMIUM_PLUS_URL_PARAM = 'premium-plus';
export const PREMIUM_URL_PARAM = 'premium';
export const STANDARD_PRO_URL_PARAM = 'standard-pro';
export const STANDARD_URL_PARAM = 'standard';

export const SHIPPING_METHOD = 'Standard: FedEx Ground - Free Shipping';

export const GEORGE_DEFAULT_ADDRESS_DATA = {
  email: {
    value: 'george.icarcover@gmail.com',
    visited: true,
    message: '',
    error: false,
  },
  firstName: {
    value: 'George',
    visited: true,
    message: '',
    error: false,
  },
  lastName: {
    value: 'Anumba',
    visited: true,
    message: '',
    error: false,
  },
  line1: {
    value: '1231 S Hill St',
    visited: true,
    message: '',
    error: false,
  },
  line2: {
    value: 'P.O. Box 424',
    visited: true,
    message: '',
    error: false,
  },
  city: {
    value: 'Los Angeles',
    visited: true,
    message: '',
    error: false,
  },
  state: {
    value: 'CA',
    visited: true,
    message: '',
    error: false,
  },
  postal_code: {
    value: '90015',
    visited: true,
    message: '',
    error: false,
  },
  phoneNumber: {
    value: '+1 424 424 4242',
    visited: true,
    message: '',
    error: false,
  },
};

export const DEFAULT_FLOOR_MAT_IMAGES_CAROUSEL = [
  'https://coverland.sfo3.cdn.digitaloceanspaces.com/Floormat/slide_image/fm-tx-rg-01.webp',
  'https://coverland.sfo3.cdn.digitaloceanspaces.com/Floormat/slide_image/fm-tx-rg-02.webp',
  'https://coverland.sfo3.cdn.digitaloceanspaces.com/Floormat/slide_image/fm-tx-rg-03.webp',
  'https://coverland.sfo3.cdn.digitaloceanspaces.com/Floormat/slide_image/fm-tx-rg-04.webp',
  'https://coverland.sfo3.cdn.digitaloceanspaces.com/Floormat/slide_image/fm-tx-rg-05.webp',
  'https://coverland.sfo3.cdn.digitaloceanspaces.com/Floormat/slide_image/fm-tx-rg-07.webp',
  'https://coverland.sfo3.cdn.digitaloceanspaces.com/Floormat/slide_image/fm-tx-or-08.webp',
  'https://coverland.sfo3.cdn.digitaloceanspaces.com/Floormat/slide_image/fm-tx-or-09.webp',
  'https://coverland.sfo3.cdn.digitaloceanspaces.com/Floormat/slide_image/fm-tx-or-10.webp',
];

export const DEFAULT_FLOOR_MAT_IMAGES_DESCRIPTION = [
  'https://coverland.sfo3.cdn.digitaloceanspaces.com/Floormat/description/fm-tx-dsc-rg-01.webp',
  'https://coverland.sfo3.cdn.digitaloceanspaces.com/Floormat/description/fm-tx-dsc-rg-02.webp',
  'https://coverland.sfo3.cdn.digitaloceanspaces.com/Floormat/description/floormat_pdp_3.webp',
  'https://coverland.sfo3.cdn.digitaloceanspaces.com/Floormat/description/floormat_pdp_4.webp',
  'https://coverland.sfo3.cdn.digitaloceanspaces.com/Floormat/description/floormat_pdp_5.webp',
  'https://coverland.sfo3.cdn.digitaloceanspaces.com/Floormat/description/floormat_pdp_6.webp',
  'https://coverland.sfo3.cdn.digitaloceanspaces.com/Floormat/description/fm-tx-dsc-rg-07.webp',
  'https://coverland.sfo3.cdn.digitaloceanspaces.com/Floormat/description/floormat_pdp_8.webp',
  'https://coverland.sfo3.cdn.digitaloceanspaces.com/Floormat/description/floormat_pdp_9.webp',
  'https://coverland.sfo3.cdn.digitaloceanspaces.com/Floormat/description/floormat_pdp_10.webp',
  'https://coverland.sfo3.cdn.digitaloceanspaces.com/Floormat/description/floormat_pdp_11.webp',
  'https://coverland.sfo3.cdn.digitaloceanspaces.com/Floormat/description/floormat_pdp_12.webp',
  'https://coverland.sfo3.cdn.digitaloceanspaces.com/Floormat/description/floormat_pdp_13.webp',
  'https://coverland.sfo3.cdn.digitaloceanspaces.com/Floormat/description/floormat_pdp_14.webp',
];
